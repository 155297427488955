import React, { useRef, useState } from 'react';
import { del } from 'aws-amplify/api';
import { Card } from 'react-bootstrap';
import CollectionIcon from '../reusable/CollectionIcon';
import { BsChevronRight } from 'react-icons/bs';
import { userFriendlyDateString } from '../../libs/miscLib';
import { useRightClickMenu } from '../browse/RightClickMenu';
import { IoMdOpen } from 'react-icons/io';
import { HiOutlineTrash } from 'react-icons/hi';
import TruckLoadingAnimation from '../reusable/TruckLoadingAnimation';
import { useNavigate } from 'react-router-dom';
import { useToastNotifications } from '../../libs/toastLib';




export default function CollectionLineItem({
  className='',
  style={},
  collectionObj,    // { collectionId, collectionMetadata, modifiedAt, permissionsLevel, collectionPuzzles }
  onClick,    // if not provided, navigate to that collection (default). Otherwise, any other function (will not navigate to that collection)
  onDelete,   // if not provided, the line item right-click menu will not offer the Delete option. Otherwise, it is a function that will execute after the server has successfully deleted
  disabled=false,    // whether it's clickable/right-clickable at all
  hoverable=true,   // whether it changes color on hover
  small=false,   // if true, it will be displayed more compact
  showChevron=true, // if true, shows the chevron (suggesting click in)
  badge=null,   // if given, a Badge that it will just insert right after the title
  ...props
}) {
  const { collectionId, collectionMetadata, modifiedAt, permissionsLevel, collectionPuzzles } = collectionObj;

  const [isHovering, setIsHovering] = useState(false);

  const { postErrorNotification } = useToastNotifications();
  const navigate = useNavigate();

  const { onRightClick, rightClickMenu } = useRightClickMenu();
  const lineItemRef = useRef(null);

  const [isDeleting, setIsDeleting] = useState(false);

  function navigateToCollection(newTab = false) {
    if (newTab) {
      window.open(`/collections/${collectionId}`, '_blank');
    } else {
      navigate(`/collections/${collectionId}`);
    }
  }

  if (!onClick) onClick = () => navigateToCollection(false);

  async function handleDelete() {
    const areYouSure = window.confirm('Are you sure you want to permanently delete this collection? Puzzles included in this collection are not deleted.');
    if (areYouSure) {
      setIsDeleting(true);
      try {
        await del({
          apiName: 'userPuzzles',
          path: `/collections/${collectionId}`,
        }).response;    // return value unnecessary; not consumed
        setIsDeleting(false);
        onDelete();
      } catch (e) {
        console.log(e);
        postErrorNotification(
          'Deletion error',
          'Unable to delete your puzzle! Please let me know if this problem continues.'
        );
        setIsDeleting(false);
      }
    }
  }

  function handleRightClick(e) {
    if (disabled) return;

    const menuItems = [
      { title: 'Open in new tab', icon: <IoMdOpen />, onClick: () => navigateToCollection(true) },
    ];
    if (onDelete) {
      menuItems.push(
        { title: 'Delete collection', icon: <HiOutlineTrash />, onClick: handleDelete, className: 'text-danger' }
      );
    }

    onRightClick({
      parentRef: lineItemRef,
      mouseEvent: e,
      menuItems,
    });
  }

  return (
    <Card
      className={`${className} my-1 mx-auto w-100 px-2 ${small ? 'py-0' : 'py-2'} purple-${isHovering ? 3 : permissionsLevel === 'MANAGE' || permissionsLevel === 'EDIT' ? 2 : 1}-bkgd`}
      style={{ minHeight: small ? '20px' : '70px', maxWidth: '400px', ...style }}
      role='button'
      ref={lineItemRef}
      onContextMenu={handleRightClick}
      onMouseEnter={() => { if (hoverable) { setIsHovering(true) } }}
      onMouseLeave={() => { if (hoverable) { setIsHovering(false) } }}
      onClick={onClick}
      {...props}
    >
    {rightClickMenu}
      <div className='d-flex'>
        {small && <CollectionIcon className='my-auto me-2' />}
        <div>
          <div className='d-flex'>
            {!small && <CollectionIcon />}
            <span className={`fw-bold ${small ? 'me-2' : 'h5 mx-2'}`}>{collectionMetadata.name || 'untitled collection'}</span>
            {badge}
          </div>
          <div className='small'>
            <strong>{Object.keys(collectionPuzzles).length} puzzles</strong> &middot; last updated {userFriendlyDateString(modifiedAt)} &middot; {
              permissionsLevel === 'MANAGE' ? 'manager' : permissionsLevel === 'EDIT' ? 'contributor' : 'viewer'
            }
          </div>
        </div>
        {showChevron && <BsChevronRight className={`my-auto ms-auto fw-bold h3`} />}
      </div>

      {isDeleting && (
        <div className='d-flex' style={{backgroundColor: 'rgb(202, 175, 222, 0.3)', width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}}>
          <span className='ms-auto my-auto'>Deleting...</span>
          <TruckLoadingAnimation className='me-auto my-auto' />
        </div>
      )}
    </Card>
  );
}