/* Reminder: also update index.js if you're adding new APIs */


const config = {
  DEV: {
    webSocket: {
      url: "wss://05vqdqte6a.execute-api.us-west-2.amazonaws.com/prod",
    },
    apiGateway: {
      REGION: "us-west-2",
      URLs: {
        userPuzzles: "https://kasnw67q96.execute-api.us-west-2.amazonaws.com/prod",
        loginTable: "https://5o9v9v5619.execute-api.us-west-2.amazonaws.com/prod/",
        ticketGenerator: "https://mjzlxwsjh4.execute-api.us-west-2.amazonaws.com/prod",
        userSupport: "https://mmwess3es1.execute-api.us-west-2.amazonaws.com/prod",
        subscriptions: "https://db33qjjw3c.execute-api.us-west-2.amazonaws.com/prod",   // TODO!
        snakeHighscores: "https://y6j4slg2fk.execute-api.us-west-2.amazonaws.com/prod",
      },
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_7jOxuTAB5",
      APP_CLIENT_ID: "7ktc333uqffb3cueoeq0hro2um",
      IDENTITY_POOL_ID: "us-west-2:51886e30-8f3a-4b6a-ba45-deb30d7c2a5d",
    },
  },

  PROD: {
    webSocket: {
      url: "wss://blhk1kv8cg.execute-api.us-west-2.amazonaws.com/prod",
    },
    apiGateway: {
      REGION: "us-west-2",
      URLs: {
        userPuzzles: "https://kf7oj6pqze.execute-api.us-west-2.amazonaws.com/prod",
        loginTable: "https://q30nhuo90d.execute-api.us-west-2.amazonaws.com/prod",
        ticketGenerator: "https://fthjozsv3f.execute-api.us-west-2.amazonaws.com/prod",
        userSupport: "https://b7ljomq9bf.execute-api.us-west-2.amazonaws.com/prod",
        subscriptions: "https://db33qjjw3c.execute-api.us-west-2.amazonaws.com/prod",
        snakeHighscores: "https://frketczw6h.execute-api.us-west-2.amazonaws.com/prod",
      },
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_AcM688q4h",
      APP_CLIENT_ID: "41jdisd3ethp1vv27ebb8jpk5o",
      IDENTITY_POOL_ID: "us-west-2:f32f52a2-ecba-4706-b221-30e8c5cb470b",
    },
  },
};

export default config;