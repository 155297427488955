import React, { useCallback, useEffect, useState } from 'react';
import { post } from 'aws-amplify/api';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { getAuthenticatedUsername } from '../../libs/authLib';
import { MdMarkEmailRead } from 'react-icons/md';
import { FaDelicious } from 'react-icons/fa';
import { TbMailX } from 'react-icons/tb';
import { useToastNotifications } from '../../libs/toastLib';




export default function MyCollectionSubscription({ collectionId }) {

  const [emailFormValue, setEmailFormValue] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayNotSubscribed, setDisplayNotSubscribed] = useState(false);  // if not subscribed, display that they're not subscribed?

  const { postErrorNotification } = useToastNotifications();

  // Uses the given email to set the isSubscribed state
  const checkMySubscriptions = useCallback(async (email) => {
    try {
      const subscribedCollectionIds = await (await post({
        apiName: 'subscriptions',
        path: '/getMySubscriptions',
        options: {
          body: {
            email: email,
          },
        },
      }).response).body.json();
      
      setIsSubscribed(subscribedCollectionIds.includes(collectionId));
    } catch (e) {
      // User is not subscribed
      console.log(email + ' is not subscribed');
      setIsSubscribed(false);
    }
  }, [collectionId]);


  // On mount, get the current username (if any) and use that to check if they're already subscribed
  useEffect(() => {
    async function getMyEmail() {
      setIsLoading(true);
      var email;
      try {
        email = await getAuthenticatedUsername();
        setEmailFormValue(email);
      } catch (e) {
        // User is unauthenticated
        setIsSubscribed(false);
        return;
      }

      console.log(email);
      await checkMySubscriptions(email);
      setIsLoading(false);
    }

    getMyEmail();
  }, [checkMySubscriptions]);


  return (
    <div className='p-3' style={{
      backgroundColor: '#c9edb7',
      borderRadius: '10px',
    }}>
      {isLoading ? (
        <div className='d-flex text-secondary fw-bold mb-2'>
          <FaDelicious className='spinning my-auto me-2' size={20} />
          <span className='my-auto'>Loading...</span>
        </div>
      ) : isSubscribed ? (
        <div className='d-flex text-success fw-bold mb-2'>
          <MdMarkEmailRead className='my-auto me-2' size={20} />
          <span className='my-auto'>You are subscribed to this collection.</span>
        </div>
      ) : displayNotSubscribed && !isSubscribed ? (
        <div className='d-flex text-secondary fw-bold mb-2'>
          <TbMailX className='my-auto me-2' size={20} />
          <span className='my-auto'>You aren't subscribed to this collection.</span>
        </div>
      ) : null}

      <p className='small text-muted'><strong>Email updates</strong> allow this collection's owners to let you know when they publish new puzzles.</p>
      <FormControl
        className='mb-2'
        type='email'
        size='sm'
        value={emailFormValue}
        onChange={e => setEmailFormValue(e.target.value)}
        placeholder='jimmy.crossword@example.com'
      />
      <Button
        className='mb-1'
        disabled={isLoading || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailFormValue)}
        variant='success'
        size='sm'
        onClick={async () => {
          setIsLoading(true)
          try {
            await post({
              apiName: 'subscriptions',
              path: '/subscribe/' + collectionId,
              options: {
                body: {
                  email: emailFormValue,
                },
              },
            }).response;

            setIsLoading(false);
            setIsSubscribed(true);
          } catch (e) {
            console.log(e);
            await checkMySubscriptions(emailFormValue);
            setIsLoading(false);
          }
        }}
      >Subscribe</Button>
      <div className='d-flex gap-1'>
        <Button
          className='text-nowrap px-0 me-2'
          disabled={isLoading || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailFormValue)}
          variant='link'
          size='sm'
          onClick={async () => {
            setIsLoading(true);
            setDisplayNotSubscribed(true);
            await checkMySubscriptions(emailFormValue);
            setIsLoading(false);
          }}
        >Check if I'm subscribed</Button>
        <Button
          className='px-0'
          disabled={isLoading || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailFormValue)}
          variant='link'
          size='sm'
          onClick={async () => {
            setIsLoading(true);
            setDisplayNotSubscribed(true);
            const email = emailFormValue;
            try {
              await post({
                apiName: 'subscriptions',
                path: '/unsubscribe/' + collectionId,
                options: {
                  body: {
                    email,
                  },
                },
              }).response;

              await checkMySubscriptions(email);
              setIsLoading(false);
            } catch (e) {
              console.log(e);
              postErrorNotification('Failed to unsubscribe', 'Our apologies - something went wrong, and we weren\'t able to unsubscribe you right now. Please get in touch if this continues.')
              await checkMySubscriptions(email);
              setIsLoading(false);
            }
          }}
        >Unsubscribe</Button>
      </div>
    </div>
  );

}



