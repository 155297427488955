import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, FormControl, OverlayTrigger, Popover, Tooltip, ProgressBar } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { fromPuzzleContentObject } from '../../libs/exportLib';
import { PuzzleInteractionStatus } from '../construct/BoardInteractionContext';
import { PuzzleMetadataKey } from '../../libs/directionsLib';
import Board from '../board/Board';
import { FaDelicious } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';
import { possiblyPluralString } from '../../libs/miscLib';
import { useToastNotifications } from '../../libs/toastLib';
import { getAuthenticatedUsername } from '../../libs/authLib';
import { getAllPlaySessions } from '../../libs/playSessionLib';




const ERROR_MESSAGES = {
  DOES_NOT_EXIST: 'Puzzle does not exist.',
  NO_PERMISSION: 'This puzzle is not shared with you.',
  UNSPECIFIED: 'Unknown error occurred.',
};



export default function CollectionPuzzleEditableLineItem({ puzzleId, publishDate, setPublishDate, onFinishEditingPublishDate, removePuzzleFromCollection, collectionIsPubliclyPlayable, ...props }) {

  const { postErrorNotification } = useToastNotifications();

  const [isEditingPublishDate, setIsEditingPublishDate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState(null);
  const [puzzleItem, setPuzzleItem] = useState(null);
  const [accessRequested, setAccessRequested] = useState(false);

  const [allPlaySessions, setAllPlaySessions] = useState();

  const componentRef = useRef(null);  // ref to the DOM component so we know how wide it is
  const [componentWidth, setComponentWidth] = useState(100);   // width of line item component in pixels, so we know when to break/wrap text etc.
  const compressedWidth = componentWidth < 600;
  useEffect(() => {
    setComponentWidth(componentRef.current.offsetWidth);
  }, []);

  const hasAttemptedLoading = useRef(false)
  const [forceReloadToggle, setForceReloadToggle] = useState(false);   // if this changes, it triggers the reload useEffect; used if there's an error and user reloads it
  function forceReload() {
    hasAttemptedLoading.current = false;
    setForceReloadToggle(!forceReloadToggle);
  }
  useEffect(() => {
    // Load the puzzle
    if (!hasAttemptedLoading.current) {
      hasAttemptedLoading.current = true;
      async function loadPuzzle() {
        setIsLoading(true);
        setCurrentErrorMessage(null);
        try {
          const res = await (await get({
            apiName: 'userPuzzles',
            path: `/userPuzzles/${puzzleId}`
          }).response).body.json();
          setPuzzleItem(res);
          onFinishEditingPublishDate();
        } catch (e) {
          console.log(e);
          if (e.response?.data?.error === 'Item not found.') {
            setCurrentErrorMessage(ERROR_MESSAGES.DOES_NOT_EXIST);
          } else if (e.response?.data?.error === 'Permission denied.') {
            setCurrentErrorMessage(ERROR_MESSAGES.NO_PERMISSION);
          } else {
            setCurrentErrorMessage(ERROR_MESSAGES.UNSPECIFIED);
          }
        }
        setIsLoading(false);
      }

      loadPuzzle();
      getAllPlaySessions(puzzleId, aps => setAllPlaySessions(aps), () => setAllPlaySessions(null));
    }
  }, [puzzleId, forceReloadToggle, onFinishEditingPublishDate]);

  const { puzzleMetadata, charGrid, clues, furnishings } = puzzleItem?.puzzleContent ? fromPuzzleContentObject(puzzleItem.puzzleContent) : {};
  const puzzleTitle = puzzleMetadata?.get(PuzzleMetadataKey.TITLE);
  const puzzleAuthor = puzzleMetadata?.get(PuzzleMetadataKey.AUTHOR);
  
  const previewPopover = (
    <Popover id={'preview-' + puzzleId}>
      <Popover.Body>
        <Board charGrid={charGrid} furnishings={furnishings} puzzleInteractionStatus={PuzzleInteractionStatus.STATUESQUE} />
      </Popover.Body>
      <Popover.Header className='py-1 text-end' style={{ width: '200px' }}>Open in new tab</Popover.Header>
    </Popover>
  );

  return <div className='d-flex w-100 px-2 py-1 border white-bkgd' ref={componentRef} {...props}>
    <div className='d-flex me-auto'>
      <div className='text-center my-auto py-1 ms-1 me-3' style={{ position: 'relative', width: 34, height: 34 }}>
        <FaDelicious className='text-secondary' size={29} style={{ opacity: 0.3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) rotate(-10deg)' }} />
        <div style={{ fontSize: 12, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>
          {charGrid ? `${charGrid.length}x${charGrid[0].length}` : '?'}
        </div>
      </div>


      {isLoading ? (
        <div className='block-text fst-italic small my-auto'>Loading puzzle details...</div>
      ) : currentErrorMessage ? (
        <div className='text-secondary fst-italic small px-2 my-auto'>
          {currentErrorMessage}
          <div>
            <Button className='p-0 me-3' variant='link' style={{fontSize: 12}} onClick={() => forceReload()}>Reload</Button>
            {currentErrorMessage === ERROR_MESSAGES.DOES_NOT_EXIST && <Button className='p-0 me-3' variant='link' style={{fontSize: 12}} onClick={() => removePuzzleFromCollection()}>Remove from collection</Button>}
            {currentErrorMessage === ERROR_MESSAGES.NO_PERMISSION && <Button className='p-0 me-3' disabled={accessRequested} variant='link' style={{fontSize: 12}} onClick={async () => {
              setAccessRequested(true);    // assumes no errors; that's okay (will post notification if it fails)
              try {
                const username = await getAuthenticatedUsername();
                await post({
                  apiName: 'userPuzzles',
                  path: '/puzzleSharing',
                  options: {
                    body: {
                      requesterEmail: username,
                      puzzleId,
                    },
                  },
                }).response;
              } catch (e) {
                postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.');
                setAccessRequested(false);
              }
            }}>{accessRequested ? 'Access requested' : 'Request access'}</Button>}
          </div>
        </div>
      ) : !puzzleItem ? (
        <div className='my-auto small fst-italic'>No puzzle info available.</div>
      ) : (
        <div>
          {compressedWidth ? (
            <div>
              <div className='fw-bold me-2'>
                {puzzleTitle}
                {((charGrid && charGrid.some(r => r.some(c => c === ''))) || (clues && Array.from(clues.values()).some(c => !c))) && <Badge className='ms-1' bg='warning'>INCOMPLETE</Badge>}
              </div>
              {puzzleAuthor && <div className='small me-2'>by {puzzleAuthor}</div>}
            </div>
          ) : (
            <div className='d-flex'>
              <span className='fw-bold me-2 my-auto'>{puzzleTitle}</span>
              {puzzleAuthor && <span className='me-2 text-muted my-auto'>by {puzzleAuthor}</span>}
              {charGrid && charGrid.some(r => r.some(c => c === '')) ? (
                <Badge className='my-auto' bg='warning'>INCOMPLETE GRID</Badge>
              ) : clues && Array.from(clues.values()).some(c => !c) ? (
                <Badge className='my-auto' bg='warning'>INCOMPLETE CLUES</Badge>
              ) : undefined}
            </div>
          )}
          <div className='d-flex small'>
            {isEditingPublishDate ? (
              <FormControl
                className='d-inline'
                style={{ maxWidth: '16rem' }}
                type='datetime-local'
                size='sm'
                autoFocus
                value={!publishDate ? '' : new Date(publishDate).toLocaleString('sv-SE', {   // https://dev.to/mendyberger/input-and-js-dates-2lhc
                  year: 'numeric', month: '2-digit', day: '2-digit',
                  hour: '2-digit', minute: '2-digit', second: '2-digit',
                }).replace(' ', 'T')}
                onChange={e => {
                  if (!e.target.value) {
                    setPublishDate(null);
                  } else {
                    setPublishDate(new Date(e.target.value).getTime());
                  }
                }}
                onBlur={() => {
                  setIsEditingPublishDate(false);
                  onFinishEditingPublishDate();
                }}
              />
            ) : publishDate === null || publishDate === undefined ? (
              <Button className='border-0 px-1 py-0' variant='warning' style={{fontSize: '12px'}} onClick={() => {
                setPublishDate(Date.now());
                setIsEditingPublishDate(true);
              }}>
                not published: set publish date
                <BsPencil className='ms-2' />
              </Button>
            ) : (
              <>
                <span className={publishDate <= Date.now() ? 'text-info' : 'text-info me-1'}>{compressedWidth ? 'on ' : `publish${publishDate <= Date.now() ? 'ed ' : 'ing '}on `}</span>
                <Button
                  className='border-0 px-1 py-0'
                  variant={publishDate <= Date.now() ? 'outline-info' : 'info'}
                  style={{fontSize: '12px'}}
                  onClick={() => setIsEditingPublishDate(true)}
                >
                  {new Date(publishDate).toLocaleString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'
                  }).replace(' PM ', 'pm ').replace(' AM ', 'am ')}
                  <BsPencil className='ms-2' />
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
    {collectionIsPubliclyPlayable && !puzzleItem?.permissions?.public?.view && (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='bottom'
        rootClose 
        overlay={<Popover className='text-center p-2 grey-1-bkgd'>This puzzle is not publicly playable. To grant public view permissions, click the Share button while editing the puzzle.</Popover>}
      >
        <Badge className='ms-1 my-auto' bg='warning'>Not public</Badge>
      </OverlayTrigger>
    )}
    {allPlaySessions && (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='bottom'
        rootClose 
        overlay={<Popover className='text-center p-2'>
          {allPlaySessions.length > 0 ? <>
            {Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'solved'), 0) / allPlaySessions.length * 100)}% solved without hints<br />
            {Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'revealed'), 0) / allPlaySessions.length * 100)}% revealed<br />
            {Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'incomplete'), 0) / allPlaySessions.length * 100)}% incomplete
          </> : 'No plays yet'}
        </Popover>}
      >
        <div className='mx-1 my-auto text-end small text-success'>
          <span>{possiblyPluralString(allPlaySessions.length, 'play')}</span>
          
          <ProgressBar style={{ height: '5px', width: '60px' }}>
            <ProgressBar
              variant='success'
              key={1}
              now={Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'solved'), 0) / allPlaySessions.length * 100)}
              style={{transition: 'none'}}
            />
            <ProgressBar
              variant='warning'
              key={2}
              now={Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'revealed'), 0) / allPlaySessions.length * 100)}
              style={{transition: 'none'}}
            />
          </ProgressBar>
        </div>
      </OverlayTrigger>
    )}
    <OverlayTrigger trigger={['hover', 'focus']} placement='left' rootClose overlay={previewPopover}>
      <Button className='rounded my-auto mx-1' variant='outline-secondary' size='sm' onClick={() => {
        window.open(`/construct/${puzzleId}`, '_blank');
      }}>
        <MdOpenInNew />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' rootClose overlay={<Tooltip>Remove from collection (puzzle is not deleted)</Tooltip>}>
      <Button className='rounded my-auto me-1' variant='outline-secondary' size='sm' onClick={() => {
        if (window.confirm('Remove this puzzle from the collection? The puzzle can still be accessed through its own URL.')) {
          removePuzzleFromCollection();
        }
      }}>
        <BsTrash />
      </Button>
    </OverlayTrigger>
  </div>
}

