import React, { useEffect, useRef, useState } from 'react';
import { get, post } from 'aws-amplify/api';
import { FaDelicious } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../App';
import { TbCircleCheckFilled, TbProgressCheck } from 'react-icons/tb';
import { fromPuzzleContentObject } from '../../libs/exportLib';
import { PuzzleMetadataKey } from '../../libs/directionsLib';
import { useRightClickMenu } from '../browse/RightClickMenu';
import { IoMdOpen } from 'react-icons/io';
import { useToastNotifications } from '../../libs/toastLib';
import { getAuthenticatedUsername } from '../../libs/authLib';
import { retrieveFromLocalStorage, retrieveFromServer } from '../../libs/playSessionLib';

const ERROR_MESSAGES = {
  DOES_NOT_EXIST: 'Puzzle does not exist.',
  NO_PERMISSION: 'This puzzle is not shared with you.',
  UNSPECIFIED: 'Unknown error occurred.',
};

// This is just used internally; it does NOT match the string values in the database
const PLAY_SESSION_STATUS = {
  LOADING: 'loading',
  NOT_STARTED: 'not started',
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
};

export default function CollectionPuzzleViewableLineItem({ className='', puzzleId, publishDate, ...props }) {

  const { windowWidth, isAuthenticated } = useAppContext();
  const { postErrorNotification } = useToastNotifications();

  const navigate = useNavigate();
  const { onRightClick, rightClickMenu } = useRightClickMenu();

  const myRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState(null);
  const [puzzleItem, setPuzzleItem] = useState(null);
  const [accessRequested, setAccessRequested] = useState(false);

  const [playSessionStatus, setPlaySessionStatus] = useState(PLAY_SESSION_STATUS.LOADING);

  const hasAttemptedLoading = useRef(false)
  const [forceReloadToggle, setForceReloadToggle] = useState(false);   // if this changes, it triggers the reload useEffect; used if there's an error and user reloads it
  function forceReload() {
    hasAttemptedLoading.current = false;
    setForceReloadToggle(!forceReloadToggle);
  }
  useEffect(() => {
    // Load the puzzle
    if (!hasAttemptedLoading.current) {
      hasAttemptedLoading.current = true;
      async function loadPuzzle() {
        // First, load the actual puzzle
        setIsLoading(true);
        setCurrentErrorMessage(null);
        try {
          const response = isAuthenticated ? await get({
            apiName: 'userPuzzles',
            path: `/userPuzzles/${puzzleId}`,
          }).response : await get({
            apiName: 'userPuzzles',
            path: `/publicPuzzles/${puzzleId}`,
          }).response;
          const res = await response.body.json();
          setPuzzleItem(res);
        } catch (e) {
          console.log(e);
          if (e.response?.data?.error === 'Item not found.') {
            setCurrentErrorMessage(ERROR_MESSAGES.DOES_NOT_EXIST);
          } else if (e.response?.data?.error === 'Permission denied.') {
            setCurrentErrorMessage(ERROR_MESSAGES.NO_PERMISSION);
          } else {
            setCurrentErrorMessage(ERROR_MESSAGES.UNSPECIFIED);
          }
        }
        setIsLoading(false);

        // Next, load the play session if it exists; need to support both localStorage and network for various situations
        retrieveFromServer(puzzleId, (res) => {
          const networkStatus = res.status;
          if (networkStatus === 'solved' || networkStatus === 'revealed') {
            setPlaySessionStatus(PLAY_SESSION_STATUS.COMPLETE);
          } else {
            const localStatus = retrieveFromLocalStorage(puzzleId)?.status;
            if (localStatus === 'solved' || localStatus === 'revealed') {
              setPlaySessionStatus(PLAY_SESSION_STATUS.COMPLETE);
            } else {
              if (networkStatus === 'incomplete' || localStatus === 'incomplete') {
                setPlaySessionStatus(PLAY_SESSION_STATUS.INCOMPLETE);
              } else {
                setPlaySessionStatus(PLAY_SESSION_STATUS.NOT_STARTED);
              }
            }
          }
        }, () => {
          const localStatus = retrieveFromLocalStorage(puzzleId)?.status;
          if (localStatus === 'solved' || localStatus === 'revealed') {
            setPlaySessionStatus(PLAY_SESSION_STATUS.COMPLETE);
          } else if (localStatus === 'incomplete') {
            setPlaySessionStatus(PLAY_SESSION_STATUS.INCOMPLETE);
          } else {
            setPlaySessionStatus(PLAY_SESSION_STATUS.NOT_STARTED);
          }
        })
      }
      loadPuzzle();
    }
  }, [puzzleId, forceReloadToggle, isAuthenticated]);

  const { puzzleMetadata, charGrid } = puzzleItem?.puzzleContent ? fromPuzzleContentObject(puzzleItem.puzzleContent) : {};
  const puzzleTitle = puzzleMetadata?.get(PuzzleMetadataKey.TITLE);
  const puzzleAuthor = puzzleMetadata?.get(PuzzleMetadataKey.AUTHOR);

  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={`${className} d-flex w-100 zoomable-mid py-2 ${isHovering ? 'teal-2-bkgd rounded' : 'white-bkgd'}`}
      style={{ position: 'relative', border: '0.5px solid grey' }}
      ref={myRef}
      role='button'
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        navigate(`/play/${puzzleId}`);
      }}
      onContextMenu={e => onRightClick({ parentRef: myRef, mouseEvent: e, menuItems: [
        { title: 'Open in new tab', icon: <IoMdOpen />, onClick: () => window.open(`/play/${puzzleId}`, '_blank').focus() },
      ]})}
      {...props}
    >
      <div className='text-center my-auto py-1 ms-1 me-3' style={{ position: 'relative', width: 34, height: 34 }}>
        <FaDelicious className='text-secondary' size={29} style={{ opacity: 0.3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) rotate(-10deg)' }} />
        <div style={{ fontSize: 12, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>
          {charGrid ? `${charGrid.length}x${charGrid[0].length}` : '?'}
        </div>
      </div>

    
      {isLoading ? (
        <div className='block-text fst-italic small my-auto'>Loading puzzle details...</div>
      ) : currentErrorMessage ? (
        <div className='text-secondary fst-italic small px-2 my-auto'>
          {currentErrorMessage}
          <div>
            <Button className='p-0 me-3' variant='link' style={{fontSize: 12}} onClick={e => {
              e.stopPropagation();
              forceReload();
            }}>Reload</Button>
            {isAuthenticated && currentErrorMessage === ERROR_MESSAGES.NO_PERMISSION && <Button className='p-0 me-3' disabled={accessRequested} variant='link' style={{fontSize: 12}} onClick={e => {
              e.stopPropagation();
              setAccessRequested(true);    // assumes no errors; that's okay (will post notification if it fails)
              async function requestAccess() {
                try {
                  const username = await getAuthenticatedUsername();
                  await post({
                    apiName: 'userPuzzles',
                    path: '/puzzleSharing',
                    options: {
                      body: {
                        requesterEmail: username,
                        puzzleId,
                      },
                    },
                  }).response;
                } catch (e) {
                  postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.');
                  setAccessRequested(false);
                }
              }
              requestAccess();
            }}>{accessRequested ? 'Access requested' : 'Request access'}</Button>}
          </div>
        </div>
      ) : !puzzleItem ? (
        <div className='my-auto small fst-italic'>No puzzle info available.</div>
      ) : (
        <>
          <div className='flex-grow-1 my-auto'>
            <div className='teal-4 me-2 my-auto'>{puzzleTitle}</div>
            <div className='small text-secondary'>
            {windowWidth > 330 ? (
              <span>{new Date(publishDate).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}{puzzleAuthor && <em> &middot; by {puzzleAuthor}</em>}</span>
            ) : (
              <>
                <div>{puzzleAuthor && <em>by {puzzleAuthor}</em>}</div>
                <div>{new Date(publishDate).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</div>
              </>
            )}
            </div>
          </div>
          <div className='d-flex align-items-center pe-2'>
            {playSessionStatus === PLAY_SESSION_STATUS.COMPLETE ? (
              <TbCircleCheckFilled className='text-success' size={26} />
            ) : playSessionStatus === PLAY_SESSION_STATUS.INCOMPLETE ? (
              <TbProgressCheck className='text-secondary' size={26} />
            ) : (
              <div />
            )}
          </div>
        </>
      )}

      {rightClickMenu}
    </div>
  );
}
