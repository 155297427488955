import React from 'react';
import { Form, Button, Popover, OverlayTrigger, InputGroup } from 'react-bootstrap';
import { AiFillDownCircle } from 'react-icons/ai';
import { PuzzleMetadataKey } from '../../libs/directionsLib';
import { PuzzleInteractionStatus, usePuzzleInteractionStatus, usePuzzleMetadata } from './BoardInteractionContext';
import './ConstructorMetadataForm.css';


/**
 * Contains editable text fields for the title, author, date, etc.
 */
export default function ConstructorMetadataForm({...props}) {

  const { puzzleMetadata, setPuzzleMetavalue } = usePuzzleMetadata();
  const { puzzleInteractionStatus } = usePuzzleInteractionStatus();

  if (puzzleMetadata.get(PuzzleMetadataKey.EDITOR) === 'ERROR') {
    throw new Error('You triggered the secret error in ContructorMetadataForm component!');
  }

  const popover = (
    <Popover id='metadata-popover' className='constructor-metadata-popover'>
      <Form className='m-2'>
        <Form.Group controlId='formGroupAuthor' className='my-2'>
          <Form.Label className='mb-0'>Author</Form.Label>
          <Form.Control 
            type='text' 
            placeholder='enter author'
            value={puzzleMetadata.get(PuzzleMetadataKey.AUTHOR)} 
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.AUTHOR, e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId='formGroupStartMessage' className='my-2'>
          <Form.Label className='mb-0'>Game start message</Form.Label>
          <Form.Control 
            as='textarea' 
            size='sm'
            placeholder='enter a message to be displayed before the game starts'
            value={puzzleMetadata.get(PuzzleMetadataKey.START_MESSAGE)} 
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.START_MESSAGE, e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId='formGroupEndMessage' className='my-2'>
          <Form.Label className='mb-0'>Game end message</Form.Label>
          <Form.Control 
            as='textarea' 
            size='sm'
            placeholder='enter a message to be displayed after solver finishes'
            value={puzzleMetadata.get(PuzzleMetadataKey.END_MESSAGE)} 
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.END_MESSAGE, e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId='formGroupEditor' className='my-2'>
          <Form.Label className='mb-0'>Editor</Form.Label>
          <Form.Control 
            type='text' 
            size='sm'
            placeholder='enter editor'
            value={puzzleMetadata.get(PuzzleMetadataKey.EDITOR)} 
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.EDITOR, e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId='formGroupCopyright' className='my-2'>
          <Form.Label className='mb-0'>Copyright</Form.Label>
          <Form.Control 
            type='text' 
            size='sm'
            placeholder='enter copyright'
            value={puzzleMetadata.get(PuzzleMetadataKey.COPYRIGHT)} 
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.COPYRIGHT, e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId='formGroupPublisher' className='my-2'>
          <Form.Label className='mb-0'>Publisher</Form.Label>
          <Form.Control 
            type='text' 
            size='sm'
            placeholder='enter publisher'
            value={puzzleMetadata.get(PuzzleMetadataKey.PUBLISHER)} 
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.PUBLISHER, e.target.value)} 
          />
        </Form.Group>
      </Form>
    </Popover>
  );

  return (
    <div style={{display: "flex", flexDirection: "row"}} {...props}>

      <InputGroup size='sm'>
        <InputGroup.Text>Title</InputGroup.Text>
        <Form.Control
          style={{borderColor: 'darkgrey'}}
          type='text'
          placeholder='untitled'
          value={puzzleMetadata.get(PuzzleMetadataKey.TITLE)}
          disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
          onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.TITLE, e.target.value)}
        />
      </InputGroup>

      <OverlayTrigger trigger='click' rootClose={true} placement='bottom' overlay={popover}>
        <Button
          className='ms-1 text-nowrap'
          size='sm'
          variant='outline-secondary'
        >
          <span className='d-none d-lg-inline me-1'>More</span>
          <AiFillDownCircle />
        </Button>
      </OverlayTrigger>
    </div>
  );
}