import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import CollectionIcon from '../components/reusable/CollectionIcon';
import { get, post } from 'aws-amplify/api';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import { BiGhost } from 'react-icons/bi';
import { AiOutlineReload } from 'react-icons/ai';
import { useAppContext } from '../App';
import { BsPencil, BsPeopleFill } from 'react-icons/bs';
import MenuButton from '../components/reusable/MenuButton';
import KeyboardEventHandler from '../libs/keyboardLib';
import { ShortcutDefinition } from '../components/reusable/MenuButton';
import { possiblyPluralString, userFriendlyDateString } from '../libs/miscLib';
import { useTraversal } from '../libs/traversalLib';
import MiniBrowse from '../components/browse/MiniBrowse';
import LoaderButton from '../components/reusable/LoaderButton';
import ShareCollectionModal from '../components/construct/toolbar/ShareCollectionModal';
import { useToastNotifications } from '../libs/toastLib';
import { getAuthenticatedUsername } from '../libs/authLib';
import CollectionPuzzleEditableLineItem from '../components/collections/CollectionPuzzleEditableLineItem';
import ManageCollectionSubscriptions from '../components/collections/ManageCollectionSubscriptions';
import { FaCirclePlus } from 'react-icons/fa6';
import { TbMailFast } from 'react-icons/tb';
import CollectionPuzzleViewableLineItem from '../components/collections/CollectionPuzzleViewableLineItem';
import MyCollectionSubscription from '../components/collections/MyCollectionSubscription';






export default function ManageCollection({initialCollectionObj}) {

  const navigate = useNavigate();
  const { isAuthenticated, brandIsShowing } = useAppContext();
  const { postErrorNotification, postNotification } = useToastNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saveCollectionFlag, setSaveCollectionFlag] = useState(false);   // just a trigger to save things via useEffect (if modifying multiple states at the same time as triggering save)
  useEffect(() => {
    saveCollection();
  // eslint-disable-next-line
  }, [saveCollectionFlag]);

  const { collectionId } = useParams();
  const [collectionObj, setCollectionObj] = useState({});
  const { collectionMetadata, modifiedAt, permissionsLevel, collectionPuzzles, subscribers } = collectionObj;
  const editable = (permissionsLevel === 'MANAGE' || permissionsLevel === 'EDIT');   // different layouts will be shown if you have edit permissions or just view permissions

  const [sortedPuzzleIds, setSortedPuzzleIds] = useState([]);
  function sortPuzzleIds() {
    // Called on blur of date inputs, eg so it doesn't keep sorting while the user is typing
    if (collectionPuzzles) {
      setSortedPuzzleIds(Object.entries(collectionPuzzles)
        .sort((a, b) => (
          (a[1].publishDate === null || a[1].publishDate === undefined) ? -1 :
          (b[1].publishDate === null || b[1].publishDate === undefined) ? 1 :
          100 * (b[1].publishDate - a[1].publishDate) + a[0].localeCompare(b[0])))   // puzzleId is the tiebreaker, to keep consistent sorting within same publish date
        .map(([puzzleId, _]) => puzzleId)
      );
    }
  }


  const [isEditingMetadata, setIsEditingMetadata] = useState(false);

  // For requesting access if permission denied
  const [isLoadingRequestAccess, setIsLoadingRequestAccess] = useState(false);
  const [hasRequestedAccess, setHasRequestedAccess] = useState(false);

  useEffect(() => {
    if (initialCollectionObj) {
      setCollectionObj(initialCollectionObj);
    } else {
      async function loadCollection() {
        setIsLoading(true);
        setIsError(false);
        try {
          const res = await (await get({
            apiName: 'userPuzzles',
            path: `/collections/${collectionId}`,
          }).response).body.json();
          setCollectionObj(res);
          setIsSaved(true);
          setIsSaving(false);
        } catch (e) {
          console.log(e);
          setIsError(true);
        }
        setIsLoading(false);
      }

      loadCollection();
    }
  }, [initialCollectionObj, collectionId]);


  async function saveCollection(onSuccess) {
    if (isSaving || (permissionsLevel !== 'EDIT' && permissionsLevel !== 'MANAGE')) return;

    setIsSaving(true);
    try {
      // This will return an object including modifiedAt - can use in the future to track potential conflicts
      const { modifiedAt } = await (await post({
        apiName: 'userPuzzles',
        path: `/collections/${collectionId}`,
        options: {
          body: {
            collectionMetadata,
            collectionPuzzles,
          },
        },
      }).response).body.json();
      setIsSaved(true);
      setCollectionObj(cObj => produce(cObj, draft => {
        draft.modifiedAt = modifiedAt;
      }));
      if (onSuccess) onSuccess();
    } catch (e) {
      console.log(e);
      postErrorNotification('Error saving collection', 'We\'re having trouble saving your collection right now. Please let us know if this continues.');
    }
    setIsSaving(false);
  }

  // Prompt on refresh; don't let user navigate away if unsaved
  useEffect(() => {
    if (!isSaved) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isSaved]);


  // Share collection functionality
  const [shareCollectionModalShowing, setShareCollectionModalShowing] = useState(false);
  const shareCollectionModal = <ShareCollectionModal collectionId={collectionId} show={shareCollectionModalShowing} onHide={() => setShareCollectionModalShowing(false)} />;
  // TODO: on close of ShareCollectionModal, make sure that the collectionObj's permissions is up-to-date, so that it can make sure the warning badges on puzzles are up to date


  
  // Add puzzle functionality
  const { 
    errorMessage,
    currentDirPath,
    currentDirStructure,
    callbacks,
  } = useTraversal({ modalNavigation: true });
  const [showAddPuzzleModal, setShowAddPuzzleModal] = useState(false);
  const [showPuzzleAlreadyExistsMessage, setShowPuzzleAlreadyExistsMessage] = useState(false);
  const [showPuzzleAddedMessage, setShowPuzzleAddedMessage] = useState(false);
  const addPuzzleModal = (
    <Modal
      show={showAddPuzzleModal}
      onHide={() => {
        setShowAddPuzzleModal(false);
        sortPuzzleIds();
        setSaveCollectionFlag(f => !f);
      }}
      onShow={() => {
        setShowPuzzleAlreadyExistsMessage(false);
        setShowPuzzleAddedMessage(false);
        callbacks.refreshDataFromServer();
      }}
    >
      <Modal.Header className='purple-2-bkgd' closeButton>
        <strong>Add a puzzle to your collection</strong>
      </Modal.Header>
      <Modal.Body>
        {showPuzzleAlreadyExistsMessage && <div className='small text-danger fst-italic'>
          This puzzle is already in the collection!
        </div>}
        {showPuzzleAddedMessage && <div className='small text-success fst-italic'>
          Puzzle added to collection!
        </div>}
        {errorMessage || <MiniBrowse dirPath={currentDirPath} folderStructure={currentDirStructure} callbacks={callbacks} hideNewFolderButton onClickOnPuzzle={({puzzleId}) => {
          // Add to collection
          if (Object.keys(collectionObj.collectionPuzzles).includes(puzzleId)) {
            setShowPuzzleAlreadyExistsMessage(true);
            setShowPuzzleAddedMessage(false);
          } else {
            setShowPuzzleAlreadyExistsMessage(false);
            setCollectionObj(produce(collectionObj, draft => {
              draft.collectionPuzzles[puzzleId] = { publishDate: null };
            }));
            setShowPuzzleAddedMessage(true);
            setIsSaved(false);
          }
        }} />}
      </Modal.Body>
    </Modal>
  )





  return (

    <div className='pb-3 graph-paper-bkgd' style={{ minHeight: '100vh' }}>
      <KeyboardEventHandler
        handleKeys={[
          'ctrl+s', 'meta+s',
        ]}
        onKeyEvent={(key, event) => {
          event.preventDefault();
          if (editable && (key === 'ctrl+s' || key === 'meta+s')) {
            saveCollection();
          }
        }}
      />

      {editable && <div
        className='d-flex w-100 purple-1-bkgd'
        style={{position: 'sticky', top: '0', paddingLeft: brandIsShowing ? '70px' : '10px', paddingRight: '60px', paddingTop: '3px', paddingBottom: '4px', zIndex: 100, boxShadow: '0 0 4px black'}}
        id='collection-navbar'
      >
        <div className='me-auto d-flex'>
          <MenuButton name='File' items={[
            {
              label: 'Save',
              shortcutDefinition: new ShortcutDefinition(true, false, 'S'),
              disabled: isSaving || (permissionsLevel !== 'MANAGE' && permissionsLevel !== 'EDIT'),
              onClick: () => saveCollection()
            },
            {
              label: 'See all collections',
              onClick: () => navigate('/manage-collections'),
            },
            {
              label: 'Construct Home',
              onClick: () => navigate(isAuthenticated ? '/construct/f' : '/'),
            },
          ]} />
        </div>

        <div className='me-3 mb-1 mt-auto text-muted text-end fst-italic' style={{fontSize: '12px'}}>
          {isSaving ? 'Saving...' : isSaved ? 'All changes saved.' : 'unsaved changes'}
        </div>

        <div className='d-flex'>
          <Button
            className='blue-button me-1'
            size='sm'
            onClick={() => setShareCollectionModalShowing(true)}
          >
            {brandIsShowing && <BsPeopleFill className='me-2' />}
            Share
          </Button>
        </div>
      </div>}


      <div className='d-flex justify-content-center'>
        {isLoading ? (
          <div className='d-flex'>
            <LoadingAnimation className='mt-5 mx-auto' />
          </div>
        ) : isError || !collectionMetadata ? (
          <div className='mt-5 text-center'>
            <BiGhost className='zoomable' size={40} />
            <div className='mt-2 d-flex'>
              <p className='mx-auto not-too-wide'>
                We're having trouble loading your collection{!collectionMetadata ? ' metadata' : ''}.
                Double-check the URL, and check with the collection owner that this is not a private collection.
              </p>
            </div>
            <Button className='mt-3' variant='warning' onClick={() => window.location.reload()}>
              <AiOutlineReload className='me-2' />
              Reload
            </Button>
          </div>
        ) : (

          <Container className='mt-4'>
            <Row>
              <Col className='d-flex flex-column gap-3 mb-3' xxl={4} lg={5} xs={12}>

                <div className='d-flex flex-column gap-2 p-3' id='collection-metadata-box' style={{
                  backgroundColor: '#a2dbfc',
                  borderRadius: '10px',
                }}>

                  {isEditingMetadata ? (
                    <Form onSubmit={e => {
                      e.preventDefault();
                      saveCollection(() => setIsEditingMetadata(false))
                    }}>
                      <div className='d-flex' id='name-row'>
                        <CollectionIcon className='me-2 my-auto' />
                        <Form.Control
                          className='w-auto d-inline'
                          autoFocus
                          placeholder='collection name'
                          value={collectionMetadata.name || ''}
                          onChange={e => {
                            setCollectionObj(produce(collectionObj, draft => { draft.collectionMetadata.name = e.target.value }));
                            setIsSaved(false);
                          }}
                          onFocus={e => e.target.select()}
                          onKeyDown={e => {
                            if (e.key === 'Enter') e.target.blur();
                          }}
                        />
                      </div>

                      <Form.Group className='my-2' controlId='description'>
                        <Form.Label>Add a description:</Form.Label>
                        <Form.Control
                          as='textarea'
                          placeholder='description'
                          size='sm'
                          value={collectionMetadata.description || ''}
                          onChange={e => {
                            setCollectionObj(produce(collectionObj, draft => { draft.collectionMetadata.description = e.target.value }));
                            setIsSaved(false);
                          }}
                        />
                        <Form.Text className='text-muted' as='p'>
                          This space is for anything you want your solvers to know about this collection!
                          It may be helpful to include constructor name(s) or how frequently you intend to post new puzzles.
                        </Form.Text>
                      </Form.Group>

                      <div className='text-end'>
                        <Button className='me-2' variant='outline-danger' size='sm' onClick={() => setIsEditingMetadata(false)}>Cancel</Button>
                        <LoaderButton variant='primary' type='submit' size='sm' isLoading={isSaving} disabled={isSaved}>Save</LoaderButton>
                      </div>
                    </Form>
                  ) : (
                    <div className='d-flex flex-column gap-2'>
                      <div className='d-flex' id='name-row'>
                        <CollectionIcon className='me-2 my-auto' />
                        <div className='h3 fw-bold my-auto'>{collectionMetadata.name || 'untitled collection'}</div>
                        {editable && <Button
                          className='ms-auto my-auto text-nowrap'
                          variant='primary'
                          size='sm'
                          onClick={() => setIsEditingMetadata(true)}
                        ><BsPencil className='my-auto me-2' />Edit</Button>}
                      </div>
                      <div className='d-flex' id='description-row'>
                        <div className='d-flex' style={{ whiteSpace: 'pre-wrap' }} onClick={() => { if (!collectionMetadata.description) setIsEditingMetadata(true) }}>
                          {collectionMetadata.description ? (
                            <div className='my-auto small'>
                              <span>{collectionMetadata.description}</span>
                            </div>
                          ) : editable ? (
                            <span className='my-auto text-muted'>[Add description]</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}

                </div>

                {editable && <div className='p-3 d-flex flex-column gap-2' id='puzzles-summary-box' style={{
                  backgroundColor: '#f2a7ed',
                  borderRadius: '10px',
                }}>
                    <strong>{Object.keys(collectionPuzzles).length} puzzles</strong>
                    {editable && <Button
                      className='d-flex text-nowrap me-auto'
                      variant='primary'
                      onClick={() => setShowAddPuzzleModal(true)}
                    >
                      <FaCirclePlus className='me-2 my-auto' />
                      Add a puzzle from library
                    </Button>}
                </div>}

                {subscribers && editable && (
                  <ManageCollectionSubscriptions subscribers={subscribers} collectionId={collectionId} collectionTitle={collectionMetadata.name} onRemoveSubscriber={(email) => {
                    setCollectionObj(co => produce(co, draft => {
                      delete draft.subscribers[email];
                    }));
                  }} />
                )}
                {!editable && (
                  <MyCollectionSubscription collectionId={collectionId} />
                )}

              </Col>


              <Col xxl={8} lg={7} xs={12}>
                <div id='puzzles-line-items' style={{ border: '1px solid black' }}>
                  {Object.entries(collectionPuzzles)
                      .sort((a, b) => editable ? sortedPuzzleIds.indexOf(a[0]) - sortedPuzzleIds.indexOf(b[0]) : b[1].publishDate - a[1].publishDate)
                      .map(([puzzleId, { publishDate }]) => (
                    editable ? (
                      <CollectionPuzzleEditableLineItem
                        key={`col-puz-${puzzleId}`}
                        puzzleId={puzzleId}
                        publishDate={publishDate}
                        setPublishDate={(newPublishDate) => {
                          setCollectionObj(cObj => produce(cObj, draft => {
                            draft.collectionPuzzles[puzzleId].publishDate = newPublishDate;   // should be a number (epoch time), not a Date object
                          }));
                          setIsSaved(false);
                        }}
                        onFinishEditingPublishDate={() => {
                          sortPuzzleIds();
                          setSaveCollectionFlag(f => !f);
                        }}
                        removePuzzleFromCollection={() => {
                          setCollectionObj(cObj => produce(cObj, draft => {
                            delete draft.collectionPuzzles[puzzleId];
                          }));
                          setIsSaved(false);
                          setSaveCollectionFlag(f => !f);
                        }}
                        collectionIsPubliclyPlayable={collectionObj?.permissions?.public?.view}
                      />
                    ) : (
                      <CollectionPuzzleViewableLineItem
                        key={`col-puz-${puzzleId}`}
                        puzzleId={puzzleId}
                        publishDate={publishDate}
                      />
                    )
                  ))}
                </div>

                <div className='d-flex'>
                  <div className='d-flex mx-auto mt-4'>
                    {editable && <div className='small text-muted fst-italic my-auto me-3 text-center'>
                      <div>{possiblyPluralString(Object.keys(collectionObj?.collectionPuzzles || {}).length, 'puzzle')}</div>
                      <div>Last modified {userFriendlyDateString(modifiedAt)}</div>
                    </div>}

                    {false /* disabling for now - backend API not functional */ && !editable && isAuthenticated && <LoaderButton
                      className='mx-auto'
                      isLoading={isLoadingRequestAccess}
                      disabled={hasRequestedAccess}
                      variant='link'
                      size='sm'
                      onClick={async () => {
                        setIsLoadingRequestAccess(true);
                        try {
                          const username = await getAuthenticatedUsername();
                          await post({
                            apiName: 'userPuzzles',
                            path: '/collectionSharing',
                            options: {
                              body: {
                                requesterEmail: username,
                                collectionId,
                              },
                            },
                          }).response;
                          postNotification({
                            icon: <TbMailFast />,
                            headline: 'Edit request sent',
                            content: 'We\'ve sent a request to the collection owner to add you as an editor of this collection.',
                          });
                          setIsLoadingRequestAccess(false);
                          setHasRequestedAccess(true);
                        } catch (e) {
                          postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.');
                          setIsLoadingRequestAccess(false);
                        }
                      }}
                    >
                      Request edit access
                    </LoaderButton>}

                    <img className='zoomable' src='/android-chrome-256x256.png' alt='crossworthy-logo' width='40px' />
                  </div>
                </div>

              </Col>

            </Row>


          </Container>

        )}
      </div>

      {editable && addPuzzleModal}
      {editable && shareCollectionModal}

    </div>
  );
}