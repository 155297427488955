import React, { useState } from 'react';
import { post } from 'aws-amplify/api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { possiblyPluralString, randomString, userFriendlyDateString } from '../../libs/miscLib';
import { BsMailbox2Flag } from 'react-icons/bs';
import { IoSend, IoThumbsUpOutline } from 'react-icons/io5';
import { HiOutlineTrash } from 'react-icons/hi';
import { useToastNotifications } from '../../libs/toastLib';



/**
 * The box that allows you to see and manage your collection's subscriptions.
 */
export default function ManageCollectionSubscriptions({ subscribers, collectionId, collectionTitle, onRemoveSubscriber }) {


  const { postErrorNotification, postNotification } = useToastNotifications();
  const [isLoading, setIsLoading] = useState(false);   // reused loading state for any component that may be loading (if one is loading, they all are loading)


  const [emailModalShowing, setEmailModalShowing] = useState(false);
  const [replyToEmail, setReplyToEmail] = useState('');
  const [senderName, setSenderName] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailMessageId, setEmailMessageId] = useState('');   // unique UUID that changes each time you open the modal


  const [manageSubscribersModalShowing, setManageSubscribersModalShowing] = useState(false);


  const manageSubscribersModal = (
    <Modal show={manageSubscribersModalShowing} onHide={() => setManageSubscribersModalShowing(false)}>
      <Modal.Header style={{ backgroundColor: '#3dcc63' }} closeButton>
        <strong>Your subscribers</strong>
      </Modal.Header>
      <Modal.Body>
        <p className='small'>{Object.keys(subscribers).length} people have signed up to receive your emails about this collection.</p>
        <ListGroup>
          {Object.entries(subscribers).sort((a, b) => b[1].t - a[1].t).map(([email, { t: timestamp }], idx) => (
            <ListGroup.Item className='d-flex align-items-center' key={`subscriber-${idx}`}>
              <span>{email}</span>
              <span className='ms-auto text-muted me-2'>{userFriendlyDateString(timestamp)}</span>
              <Button className='border-0' variant='outline-danger' disabled={isLoading} onClick={() => {
                if (window.confirm('Are you sure you want to remove this email subscriber? They will not be notified, and will no longer receive your emails.')) {
                  async function unsubscribe() {
                    setIsLoading(true);
                    try {
                      await post({
                        apiName: 'subscriptions',
                        path: '/unsubscribe/' + collectionId,
                        options: {
                          body: {
                            email: email,
                          },
                        },
                      }).response;

                      setIsLoading(false);
                      onRemoveSubscriber(email);
                    } catch (e) {
                      console.log(e);
                      postErrorNotification('Failed to remove subscriber', 'Something went wrong, and we weren\'t able to remove this subscriber. Please get in touch if this continues.')
                      setIsLoading(false);
                    }
                  }
                  unsubscribe();
                }
              }}><HiOutlineTrash /></Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );


  const emailModal = (
    <Modal show={emailModalShowing} onHide={() => setEmailModalShowing(false)} onShow={() => {
      setEmailSubject(`New crossword puzzle published`);
      setEmailBody(`Hello there!\n\nI've published my latest crossword puzzle in my collection ${collectionTitle}. I'd love if you checked it out at crossworthy.net/collections/${collectionId}.\n\nHappy puzzling!`);
      setEmailMessageId(randomString(10, 'e-'));
    }}>
      <Modal.Header className='purple-2-bkgd' closeButton>
        <strong>Email your subscribers</strong>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={async e => {
          e.preventDefault();
          if (window.confirm(`Send your email to ${possiblyPluralString(Object.keys(subscribers).length, 'subscriber')} now?`)) {
            setIsLoading(true);
            try {
              await post({
                apiName: 'subscriptions',
                path: '/sendEmail/' + collectionId,
                options: {
                  body: {
                    replyTo: replyToEmail,
                    senderName: senderName,
                    subject: emailSubject,
                    message: emailBody,
                    messageId: emailMessageId,
                  },
                },
              }).response;
              postNotification({
                variant: 'chill',
                icon: <IoThumbsUpOutline />,
                headline: 'Email sent!',
                content: 'Successfully sent your email.',
              });
              setEmailModalShowing(false);
            } catch (e) {
              console.log(e);
              postErrorNotification('Email not sent', 'We apologize - we encountered a server error trying to sned your email. Please get in touch if this continues.');
            }
            setIsLoading(false);
          }
        }}>
          <InputGroup size='sm'>
            <InputGroup.Text>Reply-to email</InputGroup.Text>
            <Form.Control type='email' value={replyToEmail} placeholder='my.email@gmail.com (optional)' onChange={e => setReplyToEmail(e.target.value)} />
          </InputGroup>
          <div className='text-muted small'>Leave this blank if you don't want subscribers to reply.</div>
          <InputGroup size='sm' className='mt-3'>
            <InputGroup.Text>Your name</InputGroup.Text>
            <Form.Control type='email' value={senderName} placeholder='May from Crossworthy (optional)' onChange={e => setSenderName(e.target.value)} />
          </InputGroup>
          <div className='text-muted small'>This helps email recipients know who the email is from.</div>
        
          <InputGroup className='my-3'>
            <InputGroup.Text>Subject</InputGroup.Text>
            <Form.Control type='text' value={emailSubject} onChange={e => setEmailSubject(e.target.value)} />
          </InputGroup>

          <Form.Control as='textarea' rows={9} autoFocus value={emailBody} onChange={e => setEmailBody(e.target.value)} />

          <div className='d-flex mt-4'>
            <Button className='ms-auto d-flex zoomable-mid' type='submit'><IoSend className='me-2 my-auto' />Send Now</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );


  return (
    <div className='p-3' style={{
      backgroundColor: '#c9edb7',
      borderRadius: '10px',
    }}>
      <div className='d-flex'>
        <strong>{possiblyPluralString(Object.keys(subscribers).length, 'email subscriber')}</strong>
        <Button className='ms-auto mt-auto' variant='link' size='sm' onClick={() => setManageSubscribersModalShowing(true)}>See/manage subscribers</Button>
        {/* <span className='ms-auto small mt-auto text-decoration-underline clickable'>See/manage subscribers</span> */}
      </div>
      <p className='text-muted small'>Use this tool to write and send emails to your subscribers, announcing new puzzles or other updates.</p>

      <Button
        className='d-flex'
        variant='primary'
        onClick={() => setEmailModalShowing(true)}
      >
        <BsMailbox2Flag className='my-auto me-2' />
        Send an email
      </Button>

      {emailModal}
      {manageSubscribersModal}
    </div>
  );

}